import React, { useRef, useEffect } from "react";
import gsap from "gsap";

function AnimatedBox() {
  const boxRef = useRef()
  const box2Ref = useRef()
  const box3Ref = useRef()
  const box4Ref = useRef()


  function anim(ref, destX, delay) {
    return (
      gsap.timeline({
        delay: delay,
        repeat: -1,
        defaults: {
          duration: 1,
          ease: 'inOut(3)',
          left: 50,
          top: 0,
          boxShadow: '5px 5px 5px grey',
        }
      })
      .to(ref, {
        rotation:-90,
        boxShadow: '150px 150px 150px black',
      })
      .to(ref, {
        y: 200,
        // ease: 'in',
        rotation: 0,
        borderRadius: 25,
        opacity: 1,
        yoyo: true,
      })
      .to(ref, {
        x: destX,
      })
      .to(ref, {
        duration: 0.5,
        delay: 2,
      })
      .to(ref, {
        x: 10000,
        duration: 3,
      })
    )
  }

  function box(ref, size, color, content, zIndex) {
    return (
      <div
      ref={ref}
      style={{
        left: 50,
        top: 50,
        position: 'absolute',
        zIndex: zIndex,
        width: size,
        height: size,
        background: color,
        borderRadius: 0,
        opacity: 0,
      }}
    >{content}</div>
    )
  }
  
  function image(url, size) {
    return (
      <img src={url} style={{
        position: "absolute",
        overflow: 'hidden',
        width: size * .8,
        height: size * .8,
        top: size * .1,
        left: size * .1,
        borderRadius: 10,
      }} />
    )
  }
  
  useEffect(() => {
    anim([boxRef.current], 600, 0)
    anim([box2Ref.current], 400, 1)
    anim([box3Ref.current], 200, 2)
    anim([box4Ref.current], 0, 3)
  });

  const pupImages = [
    'https://shop.centralparkpuppies.com/wp-content/uploads/2020/09/1599606009090_297237.jpg',
    'https://3.bp.blogspot.com/-TFh4soMzNCw/U2Sy0ieRmYI/AAAAAAAADPY/yksSJRS-8dg/s1600/playing+Shiba+inu+puppy.jpg',
    'https://i2.wp.com/perfect-puppy.com/wp-content/uploads/2018/12/1541278615491.jpg',
    'https://cdn.fotofits.com/petzlover/gallery/img/l/shiba-inu-489357.jpg',
  ]

  return (
    <div position='relative'>

    {box(boxRef, 160, 'salmon', image(pupImages[0],160), 3)}
    {box(box2Ref, 160, 'red', image(pupImages[1],160), 2)}
    {box(box3Ref, 160, 'purple', image(pupImages[2],160), 1)}
    {box(box4Ref, 160, 'blue', image(pupImages[3],160), 0)}

    </div>
  );
}

export default function PuppiesPage() {
  return (
    <div>
      <h1>Puppies!</h1>
      <AnimatedBox />
    </div>
  )
}
