import { Route, Switch } from 'react-router-dom'
import logo from './spoofwear.gif';
import './App.css';

import PuppiesPage from './pages/puppies'

function App() {
  
  return (
    <div className="App">
      <Switch>
        <Route path='/' exact>
          <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          </header>
        </Route>
        <Route path='/puppies'>
          <PuppiesPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
  